import React, { useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';

import { appContext } from '_context';
import '_fonts/fonts.css';
import { Home, Login, Privacy, Profile, Room, Terms } from '_scenes';
import { getUser } from '_services';
import { shouldForwardProp } from '_utilities';
import { GlobalStyle } from './globalStyle';

export const App = () => {
  // nested state in context is not supported in this application
  const [context, setContext] = useState({
    user: null,
    isUserLoading: true,
  });

  // set user
  useEffect(() => {
    if (context.user) return;
    const getAndSetUser = async () => {
      const user = await getUser();
      setContext((context) => ({
        ...context,
        user: user,
        isUserLoading: false,
      }));
    };
    getAndSetUser();
  }, [context.user]);

  const updateContext = useCallback((update) => {
    setContext((context) => ({
      ...context,
      ...update,
    }));
  }, [setContext]);

  return (
    <appContext.Provider value={{ context, setContext: updateContext }}>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <GlobalStyle />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/room/:dailyRoomName' element={<Room />} />
          <Route path='/terms' element={<Terms />} />
        </Routes>
      </StyleSheetManager>
    </appContext.Provider>
  );
};
