import { useEffect, useRef } from 'react';

export function useClickAway ({ handleClickAway, ref }) {
  const mouseDownTarget = useRef();

  useEffect(() => {
    function handleMouseDown (event) {
      mouseDownTarget.current = event.target;
    }

    function handleMouseUp (event) {
      const mouseUpTarget = event.target;
      if (mouseDownTarget.current !== mouseUpTarget) return;
      if (ref.current && !ref.current.contains(event.target)) {
        handleClickAway(event);
      }
    }

    function handleKeyUp (event) {
      if (ref.current && event.key === 'Escape') {
        handleClickAway(event);
      }
    }

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('keyup', handleKeyUp);

    return function () {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleClickAway, ref]);
}
