import { Layout } from '_components';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;

export const Terms = () => {
  return (
    <Layout>
      <Container>
        <h1>Terms and Conditions</h1>
        <h2>Introduction</h2>
        <p>Welcome to Rooms! These terms and conditions outline the rules and regulations for the use of our public video hangout platform.</p>
        <p>By accessing and using Rooms, you accept and agree to be bound by these terms and conditions. If you do not agree with any part of these terms and conditions, you must not use our platform.</p>

        <h2>Use of the Platform</h2>
        <h3>Eligibility</h3>
        <p>You must be at least 13 years old to use Rooms. By using Rooms, you represent and warrant that you are at least 13 years old.</p>

        <h3>Account Registration</h3>
        <p>To use certain features of Rooms, you may need to register for an account. You agree to provide accurate, current, and complete information during the registration process.</p>
        <p>You are responsible for safeguarding your account information and for all activities that occur under your account.</p>

        <h3>Prohibited Activities</h3>
        <p>You agree not to use Rooms for any unlawful purpose or in violation of these terms and conditions.</p>
        <p>You agree not to engage in any activity that could harm, disrupt, or impair the functionality of Rooms.</p>
        <p>You agree not to upload, share, or distribute any content that is illegal, harmful, offensive, or violates the rights of others.</p>

        <h2>Content and Conduct</h2>
        <h3>User Content</h3>
        <p>You are solely responsible for the content you upload, share, or distribute on Rooms.</p>
        <p>By uploading content to Rooms, you grant us a non-exclusive, worldwide, royalty-free license to use, display, and distribute your content on our platform.</p>

        <h3>Content Removal</h3>
        <p>We reserve the right to remove any content that violates these terms and conditions or that we deem inappropriate, at our sole discretion.</p>

        <h3>Reporting Violations</h3>
        <p>If you encounter any content or behavior that violates these terms and conditions, please report it to us immediately.</p>

        <h2>Privacy</h2>
        <p>Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.</p>

        <h2>Termination</h2>
        <p>We may terminate or suspend your access to Rooms at any time, without prior notice or liability, for any reason, including if you violate these terms and conditions.</p>

        <h2>Disclaimers</h2>
        <p>Rooms is provided on an "as is" and "as available" basis. We make no warranties or representations about the accuracy, reliability, or completeness of the content on our platform.</p>
        <p>We do not guarantee that Rooms will be uninterrupted, secure, or free of errors.</p>

        <h2>Limitation of Liability</h2>
        <p>To the fullest extent permitted by law, Rooms and its affiliates, officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of Rooms.</p>

        <h2>Changes to Terms and Conditions</h2>
        <p>We reserve the right to update or modify these terms and conditions at any time. We will notify you of any changes by posting the new terms and conditions on our platform. Your continued use of Rooms after any such changes constitutes your acceptance of the new terms and conditions.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about these terms and conditions, please contact us at contact [&#64;] openrooms [dot] app.</p>
      </Container>
    </Layout>
  );
};
