import styled from 'styled-components';

import { borderRadius, colors } from '_constants';

export const Button = styled.button`
  background-color: unset;
  border: 2px solid ${colors.primary};
  color: ${colors.primary};
  border-radius: ${borderRadius / 2}px;
  padding: 6px 15px;
  transition: 0.2s;
  cursor: pointer;
  font-family: unset;
  font-size: unset;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.primarys5l5};
  }

  ${({ disabled }) => disabled && `
    cursor: not-allowed;

    &:hover {
      background-color: unset;
      color: ${colors.primary};
    }
  `}

  ${({ secondary }) => secondary && `
    color: ${colors.primarys20l70};
    border-color: transparent;

    &:hover {
      background-color: ${colors.primarys20l70};
    }
  `}
`;
