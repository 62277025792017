import isPropValid from '@emotion/is-prop-valid';

// this implements the default behavior from styled-components v5
export function shouldForwardProp (propName, target) {
  if (typeof target === 'string') {
    // for HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // for other elements, forward all props
  return true;
}
