import { addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';

import { firestore } from './sources';

export const createRoom = async ({ channelId, roomDescription, roomName }) => {
  const expiry = Math.round(Date.now() / 1000) + 60 * 60 * 24; // 24 hour room expiration
  const postRoomsResult = await fetch('https://api.daily.co/v1/rooms', {
    method: 'POST',
    body: JSON.stringify({
      properties: {
        // exp: expiry,
        /* eslint-disable camelcase */
        enable_recording: 'cloud',
        /* eslint-enable */
      },
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer 2779a224539f1bd6424aae970dc753ac6492d856bff7393d967771b7551c2260',
    },
  });

  const newDailyRoom = await postRoomsResult.json();

  addDoc(collection(firestore, 'rooms'), {
    channelId,
    dailyRoomId: newDailyRoom.id,
    dailyRoomName: newDailyRoom.name,
    dailyRoomUrl: newDailyRoom.url,
    description: roomDescription,
    expiry,
    participants: [],
    name: roomName,
  });

  return newDailyRoom.name;
};

export const ejectParticipant = async ({ room, sessionId, participant }) => {
  fetch(`https://api.daily.co/v1/rooms/${room.dailyRoomName}/eject`, {
    method: 'POST',
    body: JSON.stringify({
      ids: [sessionId],
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer 2779a224539f1bd6424aae970dc753ac6492d856bff7393d967771b7551c2260',
    },
  });

  const roomDocumentReference = doc(firestore, 'rooms', room.id);
  const documentSnapshot = await getDoc(roomDocumentReference);
  const { bannedParticipants = [], participants: existingParticipants } = documentSnapshot.data();
  const filteredExistingParticipants = existingParticipants.filter((existingParticipant) => (
    existingParticipant.dailySessionId !== sessionId
  ));

  updateDoc(roomDocumentReference, {
    bannedParticipants: [
      ...bannedParticipants,
      participant.userData.email,
    ],
    participants: filteredExistingParticipants,
  });
};

export const getPresence = async () => {
  const getPresenceResult = await fetch('https://api.daily.co/v1/presence', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer 2779a224539f1bd6424aae970dc753ac6492d856bff7393d967771b7551c2260',
    },
  });
  return getPresenceResult.json();
};

export const getRoomParticipants = async ({ roomName }) => {
  const getPresenceResult = await fetch(`https://api.daily.co/v1/rooms/${roomName}/presence`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer 2779a224539f1bd6424aae970dc753ac6492d856bff7393d967771b7551c2260',
    },
  });

  const presence = await getPresenceResult.json();
  return presence.data;
};

export const getRoom = async ({ dailyRoomName }) => {
  const roomQuery = query(collection(firestore, 'rooms'), where('dailyRoomName', '==', dailyRoomName));
  const { docs } = await getDocs(roomQuery);
  return {
    id: docs[0].id,
    ...docs[0].data(),
  };
};

export const getRooms = async () => {
  const roomsQuery = query(collection(firestore, 'rooms'), where('expiry', '>=', new Date().getTime() / 1000));
  const { docs } = await getDocs(roomsQuery);
  const rooms = docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  console.log('ROOMS', rooms);
  return rooms;
};

export const joinRoom = async ({ photoUrl, roomId, sessionId, userId }) => {
  const roomDocumentReference = doc(firestore, 'rooms', roomId);
  const documentSnapshot = await getDoc(roomDocumentReference);
  const { participants: existingParticipants } = documentSnapshot.data();
  const filteredExistingParticipants = existingParticipants.filter((existingParticipant) => (
    existingParticipant.id !== userId
  ));
  const updateObject = {
    participants: [
      ...filteredExistingParticipants,
      {
        id: userId,
        photoUrl,
        dailySessionId: sessionId,
      },
    ],
  };

  updateDoc(roomDocumentReference, updateObject);
};

export const registerToUpcomingRoom = async ({ room, user }) => {
  const roomDocumentReference = doc(firestore, 'rooms', room.id);
  const documentSnapshot = await getDoc(roomDocumentReference);
  const { registeredUsers: existingRegisteredUsers = [] } = documentSnapshot.data();
  const updateObject = {
    registeredUsers: [
      ...existingRegisteredUsers,
      {
        displayName: user.displayName,
        email: user.email,
        photoUrl: user.providerData[0].photoURL,
        uid: user.uid,
      },
    ],
  };
  updateDoc(roomDocumentReference, updateObject);
};

export const removeParticipant = async ({ roomId, sessionId }) => {
  const roomDocumentReference = doc(firestore, 'rooms', roomId);
  const documentSnapshot = await getDoc(roomDocumentReference);
  const { participants: existingParticipants } = documentSnapshot.data();
  const filteredExistingParticipants = existingParticipants.filter((existingParticipant) => (
    existingParticipant.dailySessionId !== sessionId
  ));

  updateDoc(roomDocumentReference, {
    participants: filteredExistingParticipants,
  });
};
