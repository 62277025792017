import { DailyAudio, DailyProvider, useAppMessage, useLocalSessionId, useParticipantIds } from '@daily-co/daily-react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { appContext } from '_context';
import { getRoom, getRoomParticipants } from '_services';
import { Chat, Controls, PreJoin, VideoTiles } from './components';

const RoomContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px 5px;
`;

const MainContent = styled.div`
  height: calc(100% - 70px);
  display: flex;
`;

const FlyingEmoji = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  font-size: 50px;
  animation: ${({ emojiId }) => flyAnimation(emojiId)} 3s linear;
  z-index: 1;
  opacity: 0;
`;

const flyAnimation = (emojiId) => keyframes`
  from { bottom: 0%; left: 50%; opacity: 1; }
  to { bottom: 100%; left: ${(emojiId % 2 === 0) ? '80%' : '20%'}; opacity: 0; }
`;

const RoomContent = ({ room }) => {
  const [showChat, setShowChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [moderatorSessionId, setModeratorSessionId] = useState();
  const [showMessageNotification, setShowMessageNotification] = useState(false);
  const localSessionId = useLocalSessionId();
  const participantIds = useParticipantIds();
  const isModerator = moderatorSessionId === localSessionId;
  const [flyingEmojis, setFlyingEmojis] = useState([]);

  // assign moderator if necessary
  useEffect(() => {
    if (!localSessionId) return;
    const assignModeratorIfNecessary = async () => {
      const roomParticipants = await getRoomParticipants({ roomName: room.dailyRoomName });
      const activeParticipants = roomParticipants.filter(({ id }) => participantIds.includes(id));
      const oldestParticipant = activeParticipants.toSorted((a, b) => a.duration - b.duration).pop();
      const otherParticipants = activeParticipants.filter(({ id }) => id !== localSessionId);
      if (!otherParticipants.length) setModeratorSessionId(localSessionId);
      else setModeratorSessionId(oldestParticipant.id);
    };
    assignModeratorIfNecessary();
  }, [localSessionId, participantIds, room]);

  const handleNewMessage = useCallback((event) => {
    if (!event.data.msg) return;
    setMessages((existingMessages) => [
      ...existingMessages,
      {
        msg: event.data.msg,
        name: event.data.name,
      },
    ]);
    if (!showChat) {
      setShowMessageNotification(true);
    }
  }, [showChat]);

  const sendAppMessage = useAppMessage({
    onAppMessage: handleNewMessage,
  });

  const toggleChat = () => {
    if (!showChat) setShowMessageNotification(false);
    setShowChat(!showChat);
  };

  const handleFlyingEmoji = useCallback((event) => {
    if (!event.data.emoji) return;
    const newEmoji = {
      emoji: event.data.emoji,
      id: Date.now(),
    };
    setFlyingEmojis((previousEmojis) => [...previousEmojis, newEmoji]);
    setTimeout(() => {
      setFlyingEmojis((previousEmojis) => previousEmojis.filter((previousEmoji) => previousEmoji.id !== newEmoji.id));
    }, 3000);
  }, []);

  const sendFlyingEmoji = useAppMessage({
    onAppMessage: handleFlyingEmoji,
  });

  return (
    <RoomContainer>
      {flyingEmojis.map(({ emoji, id }) => (
        <FlyingEmoji emojiId={id} key={id}>
          {emoji}
        </FlyingEmoji>
      ))}
      <MainContent>
        <VideoTiles isModerator={isModerator} moderatorSessionId={moderatorSessionId} room={room} />
        {showChat && <Chat
          messages={messages}
          sendAppMessage={sendAppMessage}
          setMessages={setMessages}
          toggleChat={toggleChat}
        />}
      </MainContent>
      <Controls
        handleFlyingEmoji={handleFlyingEmoji}
        room={room}
        sendFlyingEmoji={sendFlyingEmoji}
        showMessageNotification={showMessageNotification}
        toggleChat={toggleChat}
      />
      <DailyAudio />
    </RoomContainer>
  );
};

export const Room = ({ children }) => {
  const [preJoinComplete, setPreJoinComplete] = useState(false);
  const [room, setRoom] = useState({});
  const navigate = useNavigate();
  const { dailyRoomName } = useParams();
  const { context: { isUserLoading, user } } = useContext(appContext);

  useEffect(() => {
    const redirectIfNotLoggedIn = async () => {
      if (isUserLoading) return;
      if (!user) navigate('/login', { state: { dailyRoomName } });
    };
    redirectIfNotLoggedIn();
  }, [dailyRoomName, isUserLoading, navigate, user]);

  // set room object
  useEffect(() => {
    if (!dailyRoomName) return;
    const getAndSetRoom = async () => {
      const room = await getRoom({ dailyRoomName });
      setRoom(room);
    };
    getAndSetRoom();
  }, [dailyRoomName, preJoinComplete]);

  return (
    <DailyProvider {...{
      ...dailyRoomName ? {
        url: 'https://roomsapp.daily.co/' + dailyRoomName,
      } : {},
    }}>
      {preJoinComplete ? <RoomContent room={room} /> : (
        <PreJoin room={room} setPreJoinComplete={setPreJoinComplete} />
      )}
    </DailyProvider>
  );
};
