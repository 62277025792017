import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCiynEsgNImPv7iR1-qnvKoUM7unlzEXOo',
  authDomain: 'openrooms.app',
  projectId: 'rooms-b2bdf',
  storageBucket: 'rooms-b2bdf.appspot.com',
  messagingSenderId: '56724362606',
  appId: '1:56724362606:web:7a68c6030f9992311b5d7f',
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const firebaseAuthentication = getAuth(app);
export const analytics = getAnalytics(app);
