import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { borderRadius, colors } from '_constants';
import { appContext } from '_context';
import { ReactComponent as GoogleIcon } from '_images/icons/google.svg';
import { ReactComponent as ShieldIcon } from '_images/icons/shield.svg';
import { logInWithGoogle } from '_services';

const LoginContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.div`
  font-weight: bold;
  font-size: 75px;
  font-family: Black Han;
  color: ${colors.primary};
`;

const Subtitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  max-width: 350px;
  margin-bottom: 75px;
  text-align: center;
`;

const LoginButton = styled.div`
  background-color: ${colors.white};
  color: ${colors.primarys5l5};
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: ${borderRadius}px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background-color: ${colors.white}cc; /* 80% opacity */
  }
`;

const StyledGoogleIcon = styled(GoogleIcon)`
  margin-right: 15px;
`;

const Disclaimer = styled.div`
  font-size: 12px;
  margin-top: 15px;
  opacity: 65%;
  display: flex;
  align-items: center;
`;

const StyledShieldIcon = styled(ShieldIcon)`
  margin-right: 5px;
`;

export const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setContext } = useContext(appContext);

  return (
    <LoginContainer>
      <Logo>Rooms</Logo>
      <Subtitle>The world's first public video hangout platform.</Subtitle>
      <LoginButton onClick={() => logInWithGoogle({ dailyRoomName: location.state?.dailyRoomName, navigate, setContext })}>
        <StyledGoogleIcon height={25} />Continue With Google
      </LoginButton>
      <Disclaimer><StyledShieldIcon height={15} fill={colors.white} />We do not sell or share your personal data.</Disclaimer>
    </LoginContainer>
  );
};
