export const borderRadius = 10;

export const colors = {
  primary: 'hsl(180deg 100% 40%)',
  primarys20l70: 'hsl(180deg 20% 70%)',
  primarys10l90: 'hsl(180deg 10% 90%)',
  primarys10l15: 'hsl(180deg 10% 15%)',
  primarys10l10: 'hsl(180deg 10% 10%)',
  primarys5l5: 'hsl(180deg 5% 5%)',
  primarys5l5a80: 'hsl(180deg 5% 5% / 80%)',
  red: 'hsl(0deg 100% 40%)',
  redL50: 'hsl(0deg 100% 50%)',
  white: '#ffffff',
};
