import { borderRadius, colors } from '_constants';
import styled from 'styled-components';

export const Card = styled.div`
  background-color: ${colors.primarys10l10};
  padding: 25px;
  border-radius: ${borderRadius}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
