import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Card, Layout } from '_components';
import { borderRadius, colors } from '_constants';
import { appContext } from '_context';
import { ReactComponent as AddIcon } from '_images/icons/add.svg';
import { createRoom, getPresence, getRooms } from '_services';
import { RoomTile } from './components';

const WelcomeMessageContainer = styled(Card)`
  margin-bottom: 50px;
`;

const WelcomeMessage = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
`;

const SubtitleRow = styled.div`
  display: flex;
  align-items: center;
`;

const Subtitle = styled.div`
  font-size: 14px;
  margin-left: 15px;
  opacity: 0.6;
`;

const Channel = styled.div`
  & + & {
    margin-top: 25px;
  }
`;

const ChannelName = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;

const RoomTiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const CreateRoomButton = styled.div`
  width: calc((100% / 3) - (20px / 3));
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid ${colors.primarys10l10};
  border-radius: ${borderRadius}px;
  color: ${colors.white};
  cursor: pointer;
  transition: 0.2s;
  min-height: 150px;

  &:hover {
    background-color: ${colors.primarys10l10};
  }

  @media (max-width: 850px) {
    width: calc((100% / 2) - (10px / 2));
  }
`;

const CreateRoomButtonContent = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.primarys20l70};
`;

const StyledAddIcon = styled(AddIcon)`
  margin-top: 15px;
  fill: currentColor;
`;

const CreateRoomModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080; /* 50% opacity */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardTitle = styled.div`
  margin-bottom: 15px;
`;

const InputContainer = styled.div`
  width: 100%;

  & + & {
    margin-top: 10px;
  }
`;

const InputLabel = styled.div`
  font-size: 12px;
  margin-bottom: 3px;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  border: none;
  padding: 8px 10px;
  border-radius: ${borderRadius / 2}px;
`;

const TextArea = styled.textarea`
  width: 100%;
  border-radius: ${borderRadius / 2}px;
  padding: 8px 10px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 35px;

  ${Button} + ${Button} {
    margin-left: 5px;
  }
`;

const pinnedRoomIds = [
  'Y9FJr55GXuUbNwaZSHVh',
  's2q0hA0YpFFVDuihWapN',
  '3ecuU8KMCc8XbqDfT1kc',
  '8ZBIgUqjreCAA1FA7cg7',
  'LtK9Woy6mqCBsdkls00N',
];

const upcomingRoomIds = [];

export const Home = () => {
  const [presence, setPresence] = useState({});
  const [rooms, setRooms] = useState([]);
  const [roomName, setRoomName] = useState('');
  const [roomDescription, setRoomDescription] = useState('');
  const [showCreateRoomModal, setShowCreateRoomModal] = useState(false);
  const navigate = useNavigate();
  const { context: { user } } = useContext(appContext);

  const pinnedRooms = rooms.filter(({ dailyRoomName }) => pinnedRoomIds.includes(dailyRoomName));
  const activeRooms = rooms
    .filter(({ dailyRoomName }) => !pinnedRoomIds.includes(dailyRoomName))
    .filter(({ dailyRoomName }) => !upcomingRoomIds.includes(dailyRoomName))
    .filter((room) => room.participants.length > 0);
  // const upcomingRooms = rooms.filter(({ dailyRoomName }) => upcomingRoomIds.includes(dailyRoomName));

  useEffect(() => {
    if (rooms.length) return;
    const getRoomsAsync = async () => {
      const rooms = await getRooms();
      setRooms(rooms);
    };
    getRoomsAsync();
  }, [rooms]);

  useEffect(() => {
    const getPresenceAsync = async () => {
      const presence = await getPresence();
      setPresence(presence);
    };
    getPresenceAsync();
  }, []);

  const toggleCreateRoomModal = () => {
    setShowCreateRoomModal(!showCreateRoomModal);
  };

  const handleCreateRoom = async () => {
    if (!roomName) return;
    const dailyRoomName = await createRoom({
      channelId: '8pnPzQvVvfawCuaQEUh1',
      roomDescription,
      roomName,
    });
    navigate(`/room/${dailyRoomName}`);
  };

  const redirectOrCreateRoom = () => {
    if (!user) {
      navigate('/login');
    } else {
      toggleCreateRoomModal();
    }
  };

  return (
    <>
      <Layout>
        <WelcomeMessageContainer>
          <WelcomeMessage>Be a part of the world's first public video hangout platform.</WelcomeMessage>
          <SubtitleRow>
            <Button onClick={redirectOrCreateRoom}>Create A Room</Button>
            <Subtitle>or join an existing conversation.</Subtitle>
          </SubtitleRow>
        </WelcomeMessageContainer>
        <Channel>
          <ChannelName>Active Rooms</ChannelName>
          {!!rooms.length && <RoomTiles>
            {pinnedRooms.map((room) => (
              <RoomTile isLive={Object.keys(presence).includes(room.dailyRoomName)} key={room.id} room={room} user={user} />
            ))}
            {activeRooms.map((room) => (
              <RoomTile isLive={Object.keys(presence).includes(room.dailyRoomName)} key={room.dailyRoomUrl} room={room} user={user} />
            ))}
            <CreateRoomButton onClick={redirectOrCreateRoom}>
              <CreateRoomButtonContent>
                Create Room
                <StyledAddIcon fill={colors.white} height={30}/>
              </CreateRoomButtonContent>
            </CreateRoomButton>
          </RoomTiles>}
        </Channel>
        {/* <Channel>
          <ChannelName>Upcoming Rooms</ChannelName>
          {!!upcomingRooms.length && <RoomTiles>
            {upcomingRooms.map((room) => (
              <RoomTile key={room.id} room={room} user={user} />
            ))}
          </RoomTiles>}
        </Channel> */}
      </Layout>
      {showCreateRoomModal && <CreateRoomModal>
        <Card>
          <CardTitle>Create Room</CardTitle>
          <InputContainer>
            <InputLabel>Room Name</InputLabel>
            <Input onChange={({ target: { value } }) => setRoomName(value)} value={roomName} />
          </InputContainer>
          <InputContainer>
            <InputLabel>Room Description</InputLabel>
            <TextArea
              maxLength={100}
              onChange={({ target: { value } }) => setRoomDescription(value)}
              rows={3}
              value={roomDescription}
            />
          </InputContainer>
          <ButtonRow>
            <Button onClick={toggleCreateRoomModal} secondary>Cancel</Button>
            <Button onClick={handleCreateRoom}>Create Room</Button>
          </ButtonRow>
        </Card>
      </CreateRoomModal>}
    </>
  );
};
