import { DailyVideo, useDaily, useDevices, useLocalSessionId, useParticipantProperty } from '@daily-co/daily-react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Card, Layout } from '_components';
import { borderRadius } from '_constants';
import { appContext } from '_context';
import { joinRoom } from '_services';

const StyledCard = styled(Card)`
  width: 350px;
  margin: 100px auto 0;
`;

const VideoPreview = styled(DailyVideo)`
  width: 100%;
  margin-bottom: 15px;
  border-radius: ${borderRadius}px;
`;

const InputContainer = styled.div`
  width: 100%;

  & + & {
    margin-top: 10px;
  }
`;

const InputLabel = styled.div`
  font-size: 12px;
  margin-bottom: 3px;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  border: none;
  padding: 8px 10px;
  border-radius: ${borderRadius / 2}px;
`;

const Select = styled.select`
  display: block;
  width: 100%;
  border: none;
  padding: 8px 10px;
  border-radius: ${borderRadius / 2}px;
  cursor: pointer;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 35px;

  ${Button} + ${Button} {
    margin-left: 5px;
  }
`;

export const PreJoin = ({ room, setPreJoinComplete }) => {
  const localSessionId = useLocalSessionId();
  const initialUsername = useParticipantProperty(localSessionId, 'user_name');
  const [username, setUsername] = useState('');
  const daily = useDaily();
  const {
    cameras,
    currentCam,
    currentMic,
    currentSpeaker,
    microphones,
    setCamera,
    setMicrophone,
    setSpeaker,
    speakers,
  } = useDevices();
  const navigate = useNavigate();
  const { context: { user } } = useContext(appContext);

  useEffect(() => {
    if (!daily) return;
    if (!room.dailyRoomUrl) return;
    const startCamera = async () => {
      await daily.preAuth({ url: room.dailyRoomUrl });
      daily.startCamera();
    };
    startCamera();
  }, [daily, room]);

  useEffect(() => {
    setUsername(initialUsername);
  }, [initialUsername]);

  // add email to Daily user
  useEffect(() => {
    if (!daily) return;
    if (!user) return;
    daily.setUserData({
      email: user.email,
    });
  }, [daily, user]);

  const handleChangeUsername = ({ target: { value } }) => {
    setUsername(value);
    daily.setUserName(value);
  };

  const handleChangeMicrophone = ({ target: { value } }) => {
    setMicrophone(value);
  };

  const handleChangeSpeakers = ({ target: { value } }) => {
    setSpeaker(value);
  };

  const handleChangeCamera = ({ target: { value } }) => {
    setCamera(value);
  };

  const handleJoin = async () => {
    if (!daily?._callState === 'loaded') return;
    const isBanned = room.bannedParticipants?.includes(user?.email);
    if (isBanned) return;
    const result = await daily.join({ userName: username.trim() });
    const { local: { session_id: sessionId } } = result;

    joinRoom({
      photoUrl: user.photoURL,
      roomId: room.id,
      sessionId,
      userId: user.uid,
    });
    setPreJoinComplete(true);
  };

  const handleLeave = () => {
    daily.leave();
    navigate('/');
  };

  return (
    <Layout>
      <StyledCard>
        {localSessionId && <VideoPreview sessionId={localSessionId} mirror />}
        <InputContainer>
          <InputLabel>Username</InputLabel>
          <Input
            placeholder="Username"
            onChange={handleChangeUsername}
            value={username || ''}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Microphone</InputLabel>
          <Select name="microphones" onChange={handleChangeMicrophone} value={currentMic?.device?.deviceId}>
            {microphones.map((microphone) => (
              <option key={`microphone-${microphone.device.deviceId}`} value={microphone.device.deviceId}>
                {microphone.device.label}
              </option>
            ))}
          </Select>
        </InputContainer>
        <InputContainer>
          <InputLabel>Speakers</InputLabel>
          <Select onChange={handleChangeSpeakers} value={currentSpeaker?.device?.deviceId}>
            {speakers.map((speaker) => (
              <option key={`speaker-${speaker.device.deviceId}`} value={speaker.device.deviceId}>
                {speaker.device.label}
              </option>
            ))}
          </Select>
        </InputContainer>
        <InputContainer>
          <InputLabel>Camera</InputLabel>
          <Select name="cameraOptions" onChange={handleChangeCamera} value={currentCam?.device?.deviceId}>
            {cameras.map((camera) => (
              <option key={`camera-${camera.device.deviceId}`} value={camera.device.deviceId}>
                {camera.device.label}
              </option>
            ))}
          </Select>
        </InputContainer>
        <ButtonRow>
          <Button onClick={handleLeave} secondary>Back To Start</Button>
          <Button onClick={handleJoin}>Join Room</Button>
        </ButtonRow>
      </StyledCard>
    </Layout>
  );
};
