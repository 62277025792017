// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./blackHan.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./lato.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./latoBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  Fonts are loaded here instead of globalStyles.js to eliminate
  loading fonts with multiple http requests
*/

/*
  FONT WEIGHT REFERENCE:
  black: 900
  bold: 700
  normal: 400
  light: 300
  thin: 100
*/

@font-face {
  font-family: 'Black Han';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-weight: normal;
}

@font-face {
  font-family: 'Lato';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-weight: normal;
}

@font-face {
  font-family: 'Lato';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/_fonts/fonts.css"],"names":[],"mappings":"AAAA;;;CAGC;;AAED;;;;;;;CAOC;;AAED;EACE,wBAAwB;EACxB,4CAA0B;EAC1B,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,4CAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,4CAA0B;EAC1B,iBAAiB;AACnB","sourcesContent":["/*\n  Fonts are loaded here instead of globalStyles.js to eliminate\n  loading fonts with multiple http requests\n*/\n\n/*\n  FONT WEIGHT REFERENCE:\n  black: 900\n  bold: 700\n  normal: 400\n  light: 300\n  thin: 100\n*/\n\n@font-face {\n  font-family: 'Black Han';\n  src: url('./blackHan.ttf');\n  font-weight: normal;\n}\n\n@font-face {\n  font-family: 'Lato';\n  src: url('./lato.ttf');\n  font-weight: normal;\n}\n\n@font-face {\n  font-family: 'Lato';\n  src: url('./latoBold.ttf');\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
