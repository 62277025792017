import { format } from 'date-fns';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { Button, Card, Modal } from '_components';
import { colors } from '_constants';
import { ReactComponent as RecordingEnabledIcon } from '_images/icons/recordingEnabled.svg';
import { registerToUpcomingRoom } from '_services';

const Container = styled(Card)`
  width: calc((100% / 3) - (20px / 3));
  overflow: hidden;
  justify-content: space-between;
  
  ${({ isLive }) => isLive && `
    border: 2px solid ${colors.primary};
  `}

  @media (max-width: 850px) {
    width: calc((100% / 2) - (10px / 2));
  }
`;

const MainContent = styled.div``;

const Participants = styled.div`
  margin-bottom: 15px;
  display: flex;

  @media (max-width: 850px) {
    flex-wrap: wrap;
    margin-left: 5px;
  }
`;

const ParticipantImage = styled.img`
  height: 30px;
  width: 30px;
  border: 1px solid ${colors.primarys10l10};
  border-radius: 50%;

  & + & {
    margin-left: -5px;
  }

  @media (max-width: 850px) {
    margin-left: -5px;
  }
`;

const RoomName = styled.div`
  margin-bottom: 15px;
`;

const RoomDescription = styled.div`
  opacity: 0.6;
  font-size: 14px;
`;

const RoomScheduleText = styled.div`
  margin-top: 10px;
  opacity: 0.8;
  font-size: 14px;
`;

const JoinButton = styled(Button)`
  margin-top: 20px;
  margin-left: auto;
  position: relative;
`;

const pulse = keyframes`
  0% {
    opacity: 100%;
  }
  35% {
    opacity: 100%;
  }
  100% {
    opacity: 50%;
  }
`;

const LiveRoomIconContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.primary};
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  animation: ${pulse} 1s infinite alternate linear;
`;

const LiveRoomIcon = styled(RecordingEnabledIcon)`
  fill: ${colors.primary};
  height: 14px;
  margin-right: 3px;
`;

export const RoomTile = ({ isLive, room, user }) => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const navigate = useNavigate();

  const isBanned = room.bannedParticipants?.includes(user?.email);
  const isRegistered = room.registeredUsers?.some(({ email }) => email === user?.email);

  const handleRegister = () => {
    if (!user) {
      navigate('/login');
      return;
    }
    if (isRegistered) return;
    setShowRegisterModal(!showRegisterModal);
  };

  return (
    <Container isLive={isLive}>
      <MainContent>
        <Participants>
          {room.participants.slice(-8).map((participant) => (
            <ParticipantImage key={participant.id} src={participant.photoUrl} />
          ))}
        </Participants>
        <RoomName>{room.name}</RoomName>
        <RoomDescription>{room.description}</RoomDescription>
        {room.scheduleTime && <RoomScheduleText>Join Now Or During Weekly Events - {format(new Date(room.scheduleTime), 'cccc haaa')}</RoomScheduleText>}
      </MainContent>
      {room.startTimestamp
        ? <JoinButton onClick={handleRegister} disabled={isRegistered}>{isRegistered ? 'Registered ✓' : 'Register'}</JoinButton>
        : <JoinButton as={Link} to={isBanned ? '' : `/room/${room.dailyRoomName}`} disabled={isBanned}>Join{isLive && <LiveRoomIconContainer><LiveRoomIcon />LIVE</LiveRoomIconContainer>}</JoinButton>
      }
      {showRegisterModal && <Modal closeModal={() => setShowRegisterModal(false)} onSubmit={() => registerToUpcomingRoom({ room, user })}>Do you want to register to be notified when this event begins?</Modal>}
    </Container>
  );
};
