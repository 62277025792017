import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '_constants';
import { appContext } from '_context';
import { logOut } from '_services';
import { Button } from '../button';

const StyledHeader = styled.div`
  padding: 20px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const Logo = styled.div`
  font-weight: bold;
  font-size: 22px;
  font-family: Black Han;
  color: ${colors.primary};
  cursor: pointer;
`;

const ProfileLink = styled(Link)``;

const MainContent = styled.div`
  max-width: 800px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;

  @media (max-width: 850px) {
    padding: 0 15px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 35px;
  font-size: 12px;
`;

const FooterLink = styled(Link)`
  margin-left: 15px;
`;

export const Layout = ({ children }) => {
  const navigate = useNavigate();
  const { context: { user } } = useContext(appContext);

  const toggleLogin = () => {
    if (user) {
      logOut();
    } else {
      navigate('/login');
    }
  };

  return (
    <>
      <StyledHeader>
        <Logo onClick={() => navigate('/')}>Rooms</Logo>
        <Button onClick={toggleLogin} secondary>{user ? 'Log Out' : 'Log In'}</Button>
        {/* <ProfileLink to="/profile">Profile</ProfileLink> */}
      </StyledHeader>
      <MainContent>
        {children}
      </MainContent>
      <Footer>
        <span>© {new Date().getFullYear()} Rooms. All rights reserved.</span>
        <FooterLink to="/terms">Terms</FooterLink>
        <FooterLink to="/privacy">Privacy</FooterLink>
      </Footer>
    </>
  );
};
