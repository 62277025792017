import { DailyVideo, useDaily, useDailyEvent, useLocalSessionId, useParticipantIds, useParticipantProperty, useScreenShare } from '@daily-co/daily-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { borderRadius, colors } from '_constants';
import { PackedGrid } from '_components';
import { ReactComponent as BlockIcon } from '_images/icons/block.svg';
import { ReactComponent as KeepIcon } from '_images/icons/keep.svg';
import { ReactComponent as KeepOffIcon } from '_images/icons/keepOff.svg';
import { ReactComponent as MicrophoneOffIcon } from '_images/icons/microphoneOff.svg';
import { ejectParticipant } from '_services';
import join from '_sounds/join.mp3';

const VideoTilesContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
`;

const PinnedTileGrid = styled(PackedGrid)`
  flex: 3;
`;

const VideoGrid = styled(PackedGrid)`
  flex: 1;
`;

const VideoTileContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 5px;
`;

const VideoTileContent = styled.div`
  background-color: ${colors.primarys10l10};
  position: relative;
  border-radius: ${borderRadius}px;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const ProfilePhotoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfilePhoto = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
`;

const StyledDailyVideo = styled(DailyVideo)`
  width: 100%;
  height: 100%;
  display: block;
`;

const Username = styled.div`
  position: absolute;
  bottom: 15px;
  left: 15px;
  text-shadow: 0 1px 2px rgba(0,0,0,.6), 0 0 2px rgba(0,0,0,.3);
`;

const TileControlsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const TileControls = styled.div`
  display: flex;
`;

const TileControl = styled.div`
  background-color: ${colors.primarys10l15};
  cursor: pointer;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  position: relative;
  transition: 0.2s;

  &:hover {
    opacity: 1;
  }

  & + & {
    margin-left: 5px;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: ${colors.primarys5l5};
  border-radius: ${borderRadius / 2}px;
  padding: 3px 10px;
  text-wrap: nowrap;
  opacity: 1;
  display: none;
  margin-bottom: 5px;

  ${TileControl}:hover & {
    display: initial;
  }
`;

const MicrophoneOffIconContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${colors.primarys10l15};
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoTile = ({
  isAudioOff,
  isLocalParticipant,
  isModerator,
  isScreenShare,
  isVideoOff,
  moderatorSessionId,
  participant,
  pinnedTile,
  room,
  sessionId,
  togglePinnedTile,
}) => {
  const username = useParticipantProperty(sessionId, 'user_name');
  const isPinned = pinnedTile.sessionId === sessionId && pinnedTile.isScreenShare === isScreenShare;
  const isModeratorTile = moderatorSessionId === sessionId;
  const profilePhotoUrl = room.participants.find(({ dailySessionId }) => dailySessionId === sessionId)?.photoUrl;

  const handleEjectParticipant = async () => {
    ejectParticipant(({ room, sessionId, participant }));
  };

  return (
    <VideoTileContainer>
      <VideoTileContent>
        {isVideoOff
          ? <ProfilePhotoContainer><ProfilePhoto imageUrl={profilePhotoUrl} /></ProfilePhotoContainer>
          : <StyledDailyVideo automirror fit={isScreenShare ? 'contain' : 'cover'} sessionId={sessionId} type={isScreenShare ? 'screenVideo' : 'video'} />
        }
        {!isScreenShare && <Username>{username}{isModeratorTile && ' (Moderator)'}</Username>}
        <TileControlsContainer>
          <TileControls>
            <TileControl onClick={togglePinnedTile}>
              {isPinned ? <KeepOffIcon height={35} fill={colors.white} /> : <KeepIcon height={35} fill={colors.white} />}
              <Tooltip>Pin Tile</Tooltip>
            </TileControl>
            {isModerator && !isLocalParticipant && <TileControl onClick={handleEjectParticipant}>
              <BlockIcon height={30} fill={colors.white} />
              <Tooltip>Block User</Tooltip>
            </TileControl>}
          </TileControls>
        </TileControlsContainer>
        {isAudioOff && <MicrophoneOffIconContainer>
          <MicrophoneOffIcon height={20} fill={colors.white} />
        </MicrophoneOffIconContainer>}
      </VideoTileContent>
    </VideoTileContainer>
  );
};

export const VideoTiles = ({ isModerator, moderatorSessionId, room }) => {
  const [disabledAudioTracks, setDisabledAudioTracks] = useState([]);
  const [disabledVideoTracks, setDisabledVideoTracks] = useState([]);
  const localSessionId = useLocalSessionId();
  const participantIds = useParticipantIds();
  const { screens } = useScreenShare();
  const daily = useDaily();
  console.log('DAILY', daily);
  const [pinnedTile, setPinnedTile] = useState({ isScreenShare: null, sessionId: '' });

  const participants = useMemo(() => daily._participants || {}, [daily]);

  useDailyEvent('track-stopped', useCallback((event) => {
    if (!event.participant) return;
    if (event.type === 'audio') {
      setDisabledAudioTracks((disabledAudioTracks) => [
        ...disabledAudioTracks,
        event.participant.session_id,
      ]);
    } else if (event.type === 'video') {
      setDisabledVideoTracks((disabledVideoTracks) => [
        ...disabledVideoTracks,
        event.participant.session_id,
      ]);
    }
    if (event.participant.session_id === pinnedTile.sessionId) {
      setPinnedTile({ isScreenShare: null, sessionId: '' });
    }
  }, [pinnedTile]));

  useDailyEvent('track-started', useCallback((event) => {
    if (!event.participant) return;
    if (event.type === 'audio') {
      setDisabledAudioTracks((disabledAudioTracks) => disabledAudioTracks.filter((disabledAudioTrack) => disabledAudioTrack !== event.participant.session_id));
    } else if (event.type === 'video') {
      setDisabledVideoTracks((disabledVideoTracks) => disabledVideoTracks.filter((disabledVideoTrack) => disabledVideoTrack !== event.participant.session_id));
    }
  }, []));

  useDailyEvent('participant-joined', useCallback((event) => {
    const enterAudio = new Audio(join);
    enterAudio.play();
  }, []));

  useEffect(() => {
    if (!Object.keys(participants).length) return;
    const disabledAudioTracks = Object
      .keys(participants)
      .filter((key) => participants[key].audio === false)
      .map((key) => participants[key].session_id);
    const disabledVideoTracks = Object
      .keys(participants)
      .filter((key) => participants[key].video === false)
      .map((key) => participants[key].session_id);
    setDisabledAudioTracks(disabledAudioTracks);
    setDisabledVideoTracks(disabledVideoTracks);
  }, [participantIds, participants]);

  const filteredParticipantIds = !pinnedTile.isScreenShare
    ? participantIds.filter((participantId) => participantId !== pinnedTile.sessionId)
    : participantIds;
  const filteredScreens = pinnedTile.isScreenShare
    ? screens.filter(({ session_id: sessionId }) => sessionId !== pinnedTile.sessionId)
    : screens;

  const togglePinnedTile = ({ isScreenShare, sessionId }) => () => {
    if (pinnedTile.sessionId === sessionId && isScreenShare === pinnedTile.isScreenShare) {
      setPinnedTile({ isScreenShare: null, sessionId: '' });
    } else setPinnedTile({ isScreenShare, sessionId });
  };

  return (
    <VideoTilesContainer>
      {pinnedTile.sessionId && <PinnedTileGrid boxAspectRatio={16 / 9}>
        <VideoTile
          isAudioOff={disabledAudioTracks.some((disabledAudioTrack) => disabledAudioTrack === pinnedTile.sessionId)}
          isLocalParticipant={pinnedTile.sessionId === localSessionId}
          isModerator={isModerator}
          isScreenShare={pinnedTile.isScreenShare}
          isVideoOff={disabledVideoTracks.some((disabledVideoTrack) => disabledVideoTrack === pinnedTile.sessionId)}
          moderatorSessionId={moderatorSessionId}
          participant={participants[pinnedTile.sessionId]}
          pinnedTile={pinnedTile}
          room={room}
          sessionId={pinnedTile.sessionId}
          togglePinnedTile={togglePinnedTile({
            isScreenShare: pinnedTile.isScreenShare,
            sessionId: pinnedTile.sessionId,
          })}
        />
      </PinnedTileGrid>}
      <VideoGrid boxAspectRatio={16 / 9} dependencyArray={[pinnedTile.sessionId]}>
        {filteredParticipantIds.map((participantId) => <VideoTile
          isAudioOff={disabledAudioTracks.some((disabledAudioTrack) => disabledAudioTrack === participantId)}
          isLocalParticipant={participantId === localSessionId}
          isModerator={isModerator}
          isVideoOff={disabledVideoTracks.some((disabledVideoTrack) => disabledVideoTrack === participantId)}
          key={participantId}
          moderatorSessionId={moderatorSessionId}
          participant={participants[participantId]}
          pinnedTile={pinnedTile}
          room={room}
          sessionId={participantId}
          togglePinnedTile={togglePinnedTile({
            isScreenShare: false,
            sessionId: participantId,
          })}
        />)}
        {filteredScreens.map(({ screenId, session_id: sessionId }) => <VideoTile
          isAudioOff={disabledAudioTracks.some((disabledAudioTrack) => disabledAudioTrack === sessionId)}
          isVideoOff={disabledVideoTracks.some((disabledVideoTrack) => disabledVideoTrack === sessionId)}
          isScreenShare
          key={screenId}
          pinnedTile={pinnedTile}
          room={room}
          sessionId={sessionId}
          togglePinnedTile={togglePinnedTile({
            isScreenShare: true,
            sessionId: sessionId,
          })}
        />)}
      </VideoGrid>
    </VideoTilesContainer>
  );
};
