import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';

import { firebaseAuthentication } from './sources';

const provider = new GoogleAuthProvider();

export const logInWithGoogle = ({ dailyRoomName, navigate, setContext }) => {
  return signInWithPopup(firebaseAuthentication, provider)
    .then(({ user }) => {
      // window.rdt('track', 'SignUp', {
      //   email: user.email,
      // });
      setContext({ user });
      if (dailyRoomName) {
        navigate(`/room/${dailyRoomName}`);
      } else {
        navigate('/');
      }
    });
};

export const getUser = () => {
  return new Promise((resolve, reject) => {
    firebaseAuthentication.onAuthStateChanged((user) => {
      if (user) {
        resolve({
          ...user.toJSON(),
          id: user.uid,
        });
      } else {
        resolve(null);
      }
    });
  });
};

export const logOut = async () => {
  await signOut(firebaseAuthentication);
  window.location.reload();
};
