import { useLocalSessionId, useParticipantProperty } from '@daily-co/daily-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { borderRadius, colors } from '_constants';
import { ReactComponent as CloseIcon } from '_images/icons/close.svg';
import { ReactComponent as SendIcon } from '_images/icons/send.svg';

const ChatContainer = styled.div`
  background-color: white;
  border-radius: ${borderRadius}px;
  padding: 20px;
  width: 275px;
  display: flex;
  flex-direction: column;
  color: ${colors.primarys5l5};
`;

const CloseButton = styled.div`
  margin-left: auto;
  cursor: pointer;
`;

const MessagesContainer = styled.div`
  overflow: scroll;
  word-break: break-word;
`;

const MessageSender = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const MessageText = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
`;

const MessageInputContainer = styled.form`
  margin-top: auto;
  display: flex;
`;

const MessageInput = styled.input`
  flex: 1;
  border: unset;
  border-bottom: 1px solid ${colors.primarys5l5};
  outline: unset;
`;

const SendButton = styled.button`
  height: 35px;
  width: 35px;
  border: unset;
  border-radius: 50%;
  background-color: ${colors.primarys10l90};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  cursor: pointer;
`;

export const Chat = ({ messages, sendAppMessage, setMessages, toggleChat }) => {
  const [inputValue, setInputValue] = useState('');
  const messagesContainerRef = useRef();
  const localSessionId = useLocalSessionId();
  const username = useParticipantProperty(localSessionId, 'user_name');

  // scroll to bottom when new message is received
  useEffect(() => {
    messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
  }, [messages]);

  const sendMessage = useCallback((message) => {
    sendAppMessage({
      msg: message,
      name: username || 'Guest',
    }, '*');

    setMessages([
      ...messages,
      {
        msg: message,
        name: username || 'Guest',
      },
    ]);
  },
  [messages, sendAppMessage, setMessages, username]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!inputValue.trim()) return; // don't allow people to submit empty strings
    sendMessage(inputValue);
    setInputValue('');
  };

  return (
    <ChatContainer>
      <CloseButton onClick={toggleChat}>
        <CloseIcon width="20" fill={colors.primarys5l5} />
      </CloseButton>
      <MessagesContainer ref={messagesContainerRef}>
        {messages.map((message, index) => (
          <div key={index}>
            <MessageSender>{message?.name}</MessageSender>
            <MessageText>{message?.msg}</MessageText>
          </div>
        ))}
      </MessagesContainer>
      <MessageInputContainer onSubmit={handleSubmit}>
        <MessageInput
          placeholder="Send a message"
          value={inputValue}
          onChange={handleChange}
        />
        <SendButton type="submit">
          <SendIcon width={24} fill="black" />
        </SendButton>
      </MessageInputContainer>
    </ChatContainer>
  );
};
