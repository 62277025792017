import { colors } from '_constants';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: Lato, sans-serif;
    margin: 0;
    background-color: ${colors.primarys5l5};
    color: ${colors.white};
  }

  a {
    text-decoration: none;
    color: unset;
  }
`;
