import React, { useState, useEffect, useRef, useCallback } from 'react';

import { largestRect } from 'rect-scaler';

function recalculateLayout (
  containerWidth,
  containerHeight,
  numRects,
  rectAspectRatio = 1,
) {
  return largestRect(
    containerWidth,
    containerHeight,
    numRects,
    rectAspectRatio,
    1
  );
}

function usePackedGridLayout (boxAspectRatio = 1, dependencyArray) {
  const [numBoxes, setNumBoxes] = useState(0);
  const [layout, setLayout] = useState();
  const containerRef = useRef();
  const updateLayout = useCallback((element) => {
    if (element != null) {
      containerRef.current = element;
    }
    if (numBoxes > 0 && containerRef.current) {
      const width = containerRef.current.getBoundingClientRect().width;
      const height = containerRef.current.getBoundingClientRect().height;
      setLayout(recalculateLayout(width, height, numBoxes, boxAspectRatio));
    }
  }, [boxAspectRatio, dependencyArray, numBoxes]); // TODO: revisit this line to consider adding reference to dependencyArray inside useCallback
  useEffect(() => {
    updateLayout();
    const listener = () => updateLayout();
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [updateLayout]);

  return [layout, setNumBoxes, updateLayout];
}

export function PackedGrid ({
  boxAspectRatio,
  boxClassName,
  children: _children,
  className,
  dependencyArray,
  updateLayoutRef,
}) {
  const children = React.Children.toArray(_children).filter(Boolean);
  const [layout, setNumBoxes, updateLayout] = usePackedGridLayout(
    boxAspectRatio,
    dependencyArray,
  );

  useEffect(() => {
    setNumBoxes(React.Children.count(children));
  }, [children]); // TODO: fix missing dependency

  useEffect(() => {
    if (updateLayoutRef) {
      updateLayoutRef.current = () => updateLayout();
    }
    return () => {
      if (updateLayoutRef) {
        updateLayoutRef.current = undefined;
      }
    };
  }, [updateLayout, updateLayoutRef]);

  return (
    <div
      className={className}
      ref={updateLayout}
      style={{
        display: 'flex',
        flexFlow: 'row wrap',
        placeContent: 'center',
      }}
    >
      {React.Children.map(children, (child) => (
        <div
          className={boxClassName}
          style={
            layout
              ? {
                display: 'block',
                width: '100%',
                height: '100%',
                maxWidth: `${layout.width}px`,
                maxHeight: `${layout.height}px`,
              }
              : {
                display: 'none',
              }
          }
        >
          {child}
        </div>
      ))}
    </div>
  );
}
