import { Layout } from '_components';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;

export const Privacy = () => {
  return (
    <Layout>
      <Container>
        <h1>Privacy Policy</h1>
        <h2>Introduction</h2>
        <p>Rooms ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our public video hangout platform ("Rooms"). Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not use our platform.</p>

        <h2>Information We Collect</h2>
        <h3>Personal Information</h3>
        <p>When you use Rooms, we may collect personal information that you provide to us, including:</p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Profile picture</li>
          <li>Any other information you choose to provide</li>
        </ul>

        <h3>Usage Data</h3>
        <p>We may also collect information about your usage of Rooms, including:</p>
        <ul>
          <li>IP address</li>
          <li>Browser type and version</li>
          <li>Operating system</li>
          <li>Pages you visit and the time and date of your visit</li>
          <li>The time spent on those pages and other statistics</li>
        </ul>

        <h3>Cookies and Tracking Technologies</h3>
        <p>We use cookies and similar tracking technologies to track the activity on Rooms and hold certain information. Cookies are files with a small amount of data that are stored on your device. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some features of Rooms.</p>

        <h2>How We Use Your Information</h2>
        <p>We may use the information we collect for various purposes, including:</p>
        <ul>
          <li>To provide, operate, and maintain Rooms</li>
          <li>To improve, personalize, and expand Rooms</li>
          <li>To understand and analyze how you use Rooms</li>
          <li>To develop new products, services, features, and functionality</li>
          <li>To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to Rooms, and for marketing and promotional purposes</li>
          <li>To process your transactions and manage your orders</li>
          <li>To send you emails</li>
          <li>To find and prevent fraud</li>
          <li>To comply with legal obligations</li>
        </ul>

        <h2>Sharing Your Information</h2>
        <p>We may share your information in the following situations:</p>
        <ul>
          <li><strong>With service providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
          <li><strong>For legal purposes:</strong> We may disclose your information to comply with legal obligations, respond to requests from public and government authorities, and to protect our rights, privacy, safety, or property, and/or that of you or others.</li>
          <li><strong>With your consent:</strong> We may share your information with your consent or at your direction.</li>
        </ul>

        <h2>Data Security</h2>
        <p>We use reasonable administrative, technical, and physical measures to protect your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.</p>

        <h2>Your Data Protection Rights</h2>
        <p>Depending on your location, you may have the following rights regarding your personal information:</p>
        <ul>
          <li><strong>The right to access:</strong> You have the right to request copies of your personal information.</li>
          <li><strong>The right to rectification:</strong> You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
          <li><strong>The right to erasure:</strong> You have the right to request that we erase your personal information, under certain conditions.</li>
          <li><strong>The right to restrict processing:</strong> You have the right to request that we restrict the processing of your personal information, under certain conditions.</li>
          <li><strong>The right to object to processing:</strong> You have the right to object to our processing of your personal information, under certain conditions.</li>
          <li><strong>The right to data portability:</strong> You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
        </ul>
        <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: contact [&#64;] openrooms [dot] app</p>

        <h2>Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at contact [&#64;] openrooms [dot] app.</p>
      </Container>
    </Layout>
  );
};
