import React, { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { colors } from '_constants';
import { Button } from '../button';
import { Card } from '../card';

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primarys5l5a80};
`;

const StyledModal = styled(Card)`
  width: 450px;
  max-width: 90%;
`;

const ModalContent = styled.div`
  padding: ${({ contentPadding }) => contentPadding === undefined ? '25px' : contentPadding};
  flex: 1;
  width: 100%;
`;

const ModalButton = styled(Button)`
  & + & {
    margin-left: 15px;
  }
`;

const ButtonRow = styled.div`
  padding: 25px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const modalRoot = document.getElementById('modalRoot');

export const Modal = ({
  children,
  closeModal,
  onSubmit,
}) => {
  const ref = useRef();

  useEffect(() => {
    function handleOutsideClick (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeModal(event);
      }
    }
    document.addEventListener('mouseup', handleOutsideClick);

    return function () {
      document.removeEventListener('mouseup', handleOutsideClick);
    };
  }, [closeModal, ref]);

  const handleSubmit = () => {
    onSubmit();
    closeModal();
  };

  return createPortal(
    <ModalContainer>
      <StyledModal ref={ref} onClick={(event) => event.stopPropagation()}>
        <ModalContent>{children}</ModalContent>
        {<ButtonRow>
          {<ModalButton onClick={closeModal} secondary>Go Back</ModalButton>}
          {<ModalButton onClick={handleSubmit}>Register</ModalButton>}
        </ButtonRow>}
      </StyledModal>
    </ModalContainer>,
    modalRoot,
  );
};
