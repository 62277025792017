import { useAudioTrack, useDaily, useLocalSessionId, useScreenShare, useVideoTrack } from '@daily-co/daily-react';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { borderRadius, colors } from '_constants';
import { useClickAway } from '_hooks';
import { ReactComponent as CallEndIcon } from '_images/icons/callEnd.svg';
import { ReactComponent as ChatIcon } from '_images/icons/chat.svg';
import { ReactComponent as MicrophoneIcon } from '_images/icons/microphone.svg';
import { ReactComponent as MicrophoneOffIcon } from '_images/icons/microphoneOff.svg';
import { ReactComponent as ScreenShareIcon } from '_images/icons/screenShare.svg';
import { ReactComponent as ScreenShareOffIcon } from '_images/icons/screenShareOff.svg';
import { ReactComponent as ThumbUpIcon } from '_images/icons/thumbUp.svg';
import { ReactComponent as VideoIcon } from '_images/icons/video.svg';
import { ReactComponent as VideoOffIcon } from '_images/icons/videoOff.svg';
import { removeParticipant } from '_services';

const Container = styled.div`
  padding-top: 20px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  height: 70px;
`;

const CircleButton = styled.div`
  background-color: ${colors.primarys10l10};
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s;
  position: relative;

  & + & {
    margin-left: 10px;
  }

  &:hover {
    background-color: ${colors.primarys10l15};
  }

  ${({ disabled }) => disabled && `
    cursor: not-allowed;
  `}

  ${({ red }) => red && `
    background-color: ${colors.red};

    &:hover {
      background-color: ${colors.redL50};
    }
  `}

  ${({ showNotification }) => showNotification && `
    &::after {
      content: '';
      height: 10px;
      width: 10px;
      background-color: ${colors.red};
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 50%;
    }
  `}
`;

const EmojiOptions = styled.div`
  position: absolute;
  display: flex;
  background-color: ${colors.primarys10l10}; 
  padding: 10px;
  bottom: calc(100% + 3px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: ${borderRadius}px;
`;

const Emoji = styled.div`
  font-size: 20px;
  padding: 3px;
  border-radius: ${borderRadius / 2}px;
  transition: 0.2s;

  &:hover {
    background-color: ${colors.primarys10l15};
  }
`;

const emojis = ['👍', '🎉', '🔥', '👏', '😂', '😭'];

export const Controls = ({ handleFlyingEmoji, room, sendFlyingEmoji, showMessageNotification, toggleChat }) => {
  const navigate = useNavigate();
  const daily = useDaily();
  const localSessionId = useLocalSessionId();
  const localVideo = useVideoTrack(localSessionId);
  const localAudio = useAudioTrack(localSessionId);
  const { isSharingScreen, startScreenShare, stopScreenShare } = useScreenShare();
  const [showEmojiOptions, setShowEmojiOptions] = useState(false);
  const emojiOptionsRef = useRef();

  useClickAway({
    handleClickAway: () => setShowEmojiOptions(false),
    ref: emojiOptionsRef,
  });

  const handleEmojiClick = (emoji) => {
    sendFlyingEmoji({ emoji });
    handleFlyingEmoji({ data: { emoji } });
  };

  const toggleVideo = () => daily.setLocalVideo(localVideo.isOff);
  const toggleAudio = () => daily.setLocalAudio(localAudio.isOff);
  const toggleScreenShare = () => isSharingScreen ? stopScreenShare() : startScreenShare();
  const leaveCall = () => {
    removeParticipant(({ roomId: room.id, sessionId: localSessionId }));
    daily.leave();
    navigate('/');
  };

  return (
    <Container>
      <CircleButton onClick={toggleAudio}>{localAudio.isOff
        ? <MicrophoneOffIcon fill={colors.white} height={25} />
        : <MicrophoneIcon fill={colors.white} height={25} />
      }</CircleButton>
      <CircleButton onClick={toggleVideo}>{localVideo.isOff
        ? <VideoOffIcon fill={colors.white} height={25} />
        : <VideoIcon fill={colors.white} height={25} />
      }</CircleButton>
      <CircleButton onClick={toggleScreenShare}>{isSharingScreen
        ? <ScreenShareOffIcon fill={colors.white} height={25} />
        : <ScreenShareIcon fill={colors.white} height={25} />
      }</CircleButton>
      <CircleButton onClick={toggleChat} showNotification={showMessageNotification}>
        <ChatIcon fill={colors.white} height={25} />
      </CircleButton>
      <CircleButton onClick={() => setShowEmojiOptions(!showEmojiOptions)}>
        {showEmojiOptions && (
          <EmojiOptions ref={emojiOptionsRef}>
            {emojis.map((emoji) => (
              <Emoji key={emoji} onClick={() => handleEmojiClick(emoji)}>
                {emoji}
              </Emoji>
            ))}
          </EmojiOptions>
        )}
        <ThumbUpIcon fill={colors.white} height={25} />
      </CircleButton>
      <CircleButton onClick={leaveCall} red>
        <CallEndIcon fill={colors.white} height={25} />
      </CircleButton>
    </Container>
  );
};
